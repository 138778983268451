import * as React from "react"
import { graphql } from "gatsby"
import SingleBox from "../components/singlebox"
import Grid from "../components/grid"
import { homepage } from "./index.module.scss"
import Seo from "../components/seo"

const shuffleArray = (randomPhotos, stories) => {
  randomPhotos = randomPhotos.sort(() => 0.5 - Math.random()).slice(0, 18)
  var elements = []
  randomPhotos.map((data, i) =>
    elements.push({data, type:"photo"})
  )
  stories.map((data, i) =>
    elements.push({data, type:"story"})
  )
  elements.push({data: '', type:"empty"})
  elements.sort(() => 0.5 - Math.random())

  return elements
}

const IndexPage = ({data}) => {
  let randomPhotos = data.allIndexImagesJson.nodes
  let stories = data.allMdx.nodes
  const [shuffled, setShuffled] = React.useState([]); 

  React.useLayoutEffect(() => {
    setShuffled(shuffleArray(randomPhotos, stories))
  }, [randomPhotos, stories])
  
  return (
    <div className={homepage}>
      <Seo title="Homepage"/>
      <Grid>
        <SingleBox type="title" key="title" />
        {
          shuffled.map((element, i) => {
            if (element.type === 'photo'){
              return <SingleBox type="photo" data={element.data.image} alt={element.data.text} key={i} />
            }
            if (element.type === 'story'){
              return <SingleBox type="story" data={element.data} key={i} />
            }
            if (element.type === 'empty'){
              return <SingleBox type="empty" key={i} />
            }
            return ''
          })
        }
      </Grid>
    </div>
  )
}


export const query = graphql`
  query {
    allIndexImagesJson {
      nodes {
        title
        text
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 300
              height: 300
            )
          }
        }
      }
    }
    allMdx(sort: {order: ASC, fields: [frontmatter___title]}) {
      nodes {
        slug
        frontmatter {
          title
          subtitle
        }
      }
    }
  }
`
export default IndexPage
